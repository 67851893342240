export enum EtaCommande {
  CreatedCommande = '0',
  ValidCommande = '1', // Manual_Sent on preparation
  SendOnMissionCommande = '2', // Manual_Sent on mission
  AssignedCommande = '3', // Manual_Assigned
  InProgressCommande = '4', // Manual_Inprogress
  PendingComande = '5',
  PickupCommande = '6', // pick up

  //App mission
  MissionUnassigned = '7',
  MissionAssigned = '8',
  MissionInprogress = '9',
  MissionPending = '10',
  MissionDone = '11',
  ArticleValCommande = '79-0',
  ArticlePickupCommande = '79-2',
}
