export default function ConfirmedIcon() {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="13.5"
        cy="13.5"
        r="12.75"
        stroke="#01E37A"
        stroke-width="1.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 18.5218L6.00014 9.24652L14.0001 6L22 9.24652L21.9999 18.5218L14.0001 22.0002L6 18.5218ZM17.8544 11.5199C18.1414 11.048 17.9915 10.4328 17.5196 10.1458C17.0478 9.85885 16.4326 10.0087 16.1456 10.4806L13.2485 15.2441L11.7882 13.3737C11.4484 12.9384 10.82 12.861 10.3846 13.2008C9.94929 13.5407 9.8719 14.1691 10.2118 14.6044L12.5626 17.6156C12.7647 17.8745 13.0811 18.0176 13.409 17.9985C13.7369 17.9794 14.0345 17.8005 14.2052 17.5199L17.8544 11.5199Z"
        fill="#01E37A"
      />
    </svg>
  )
}
