import { Col, Form, Row, Spin, Switch } from 'antd'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { cloneDeep, isEmpty, uniqBy } from 'lodash'
import { ReactNode, useEffect, useState } from 'react'
import { ClientRule } from '../../../../../enum/ClientRule'
import { CommandeType } from '../../../../../enum/CommandeType'
import { InputType } from '../../../../../enum/InputType'
import { MODE } from '../../../../../enum/mode'
import { sourceType } from '../../../../../enum/sourceType'
import referenceApi from '../../../../../http/referenceApi'
import { CommandeResponse, Conditionnement } from '../../../../../models'
import { CommonError } from '../../../../../models/common/error'
import {
  isGreatThan,
  isLessThan,
  removeTonesUpperCase,
} from '../../../../../utils'
import ITable from '../../../stockscreen/component/ITable'
import SectionLayout from '../../../stockscreen/component/SectionLayout'
import {
  InvalidProperty,
  NameAndValue,
  ResetProperty,
} from '../../../stockscreen/component/Wrapper'
import {
  ActionParam,
  DataTableParam,
  ForceShowError,
  ForceShowWarning,
  FormatRowCol,
  LineParam,
  ObserveAndTransform,
  OptionType,
  TitleParam,
} from '../../../stockscreen/component/model'
import {
  checkExistWaitingLine,
  getDetailLinesNeedAndNotNeedToRemove,
  getTotalQteConfirmOfLine,
  getIndexes,
  getLineClass,
  initDataOfDetailTable,
  lockLineAfterConfirm,
  mapRuptureCheckLotToMainLine,
  mapSsccMotifToParentLine,
} from './func'
import data from './init-detail-data.json'
import CSwitch from '../../../../../components/Common/CSwitch/CSwitch'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../app/store'
import { focusById } from '../../../../../hook/usePressTab'

const cInputProps = {
  className: 'border-children-cell-table text-center',
  requiredMessage: '',
  validates: [],
}
const formatCols: FormatRowCol[] = []

interface DetailSectionProps {
  mode?: MODE
  onChangeData?: (value: DataTableParam[][]) => void
  onCheckValid?: (value: boolean) => void
  alert?: ReactNode
  detailError?: CommonError[]
  initData?: DataTableParam[][]
  onChangeInit?: (isInit: boolean) => void
  onBackToInit?: (isInit: boolean) => void
  onDeleteLine?: (id: string) => void
  valuePhoto?: number
  cmdResponse?: CommandeResponse
  isComplete: boolean
  screen: CommandeType
  clientRule?: ClientRule
  onEditing?: (isEditing: boolean) => void
  block?: boolean
  onCheckExistWaitingLine?: (isExitsWaitingLine: boolean) => void
  onChangeForcerDluo?: (value: boolean) => void
}

const firstLineData = JSON.parse(JSON.stringify(data)) as DataTableParam[]

function DetailSectionMEP(props: DetailSectionProps) {
  const { block = false } = props
  const onSearchReference = (keyword: string): Promise<OptionType[]> => {
    return referenceApi.searchReferenceWithFournisser(keyword).then((res) => {
      const result: OptionType[] = res.data.entry.map((item) => {
        return {
          label: item.ref_nom,
          value: item.ref_nom,
        }
      })
      return result
    })
  }
  const { cmdResponse } = props
  const today = dayjs()
  const commandeKey = useSelector((state: RootState) => state.selector).data
    .commande

  const [header] = useState<TitleParam[]>([
    {
      name: 'source',
      required: false,
      cInputProps: {
        width: 50,
        invisible: true,
      },
    },
    {
      label: 'Référence',
      required: true,
      name: 'reference',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        maxLength: 20,
        type: [InputType.C_SEARCH],
        width: 200,
        onSearch: onSearchReference,
        invisibleIfChild: true,
        cellClass: 'py-2',
      },
    },
    {
      label: 'Libellé',
      name: 'libelle',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        maxLength: 20,
        type: [InputType.ALL_TEXT],
        width: 200,
        invisibleIfChild: true,
      },
    },
    {
      label: 'Lot',
      name: 'lot',
      cInputProps: {
        ...cInputProps,
        className: 'border-children-cell-table text-center w-[200px]',
        cellClass: 'bd-right-cell-table',
        format: removeTonesUpperCase,
        element: (props) => {
          const source = props.line?.find(
            (i: any) => i.name === 'source'
          )?.value
          const isManualLot = props.line?.find(
            (i: any) => i.name === 'is_manual_lot'
          )?.value
          return (
            <div
              className="wrapper-table table-cell border-children-cell-table"
              style={{ borderRight: '1px solid #e5e5e5' }}
            >
              <div className="flex items-center justify-center">
                <p
                  className={clsx(
                    'text-md w-[200px] text-center',
                    source === sourceType.MANUAL &&
                      isManualLot === '1' &&
                      '!font-semibold'
                  )}
                >
                  {props.value || '-'}
                </p>
              </div>
            </div>
          )
        },
      },
    },

    {
      label: 'SSCC',
      name: 'sscc',
      cInputProps: {
        ...cInputProps,
        className: 'border-children-cell-table text-center w-[200px]',
        cellClass: 'bd-right-cell-table',
        maxLength: 18,
        width: 200,
        textStyleFollowValue: (
          value: string,
          line?: LineParam[],
          data?: DataTableParam[][]
        ) => {
          const source = line?.find((i: any) => i.name === 'source')?.value
          const isManualSSCC = line?.find(
            (i: any) => i.name === 'is_manual_sscc'
          )?.value
          if (source === sourceType.MANUAL && isManualSSCC === '1')
            return '!font-semibold'
          return ''
        },
      },
    },
    {
      label: 'DLUO',
      name: 'dluo',
      cInputProps: {
        ...cInputProps,
        // className: cInputProps.className + ' w-26',
        // maxLength: 15,
        // type: [InputType.DATE],
        className: 'border-children-cell-table text-center w-[150px]',
        cellClass: 'bd-right-cell-table',
        width: 150,
        element: (props) => {
          const source = props.line?.find(
            (i: any) => i.name === 'source'
          )?.value
          const isManualDLUO = props.line?.find(
            (i: any) => i.name === 'is_manual_dluo'
          )?.value
          return (
            <div
              className="wrapper-table table-cell border-children-cell-table"
              style={{ borderRight: '1px solid #e5e5e5' }}
            >
              <div className="flex items-center justify-center">
                <p
                  className={clsx(
                    'text-md w-[150px] text-center',
                    source === sourceType.MANUAL &&
                      isManualDLUO === '1' &&
                      '!font-semibold',
                    dayjs(props.value, t('date-format')).isBefore(today) &&
                      !cmdResponse?.force_dlou
                      ? '!font-semibold text-red'
                      : ''
                  )}
                >
                  {props.value || '-'}
                </p>
              </div>
            </div>
          )
        },
      },
    },
    {
      label: 'Statut',
      name: 'statut',
      cInputProps: {
        ...cInputProps,
        disabled: true,
        className: cInputProps.className + ' w-26',
        width: 200,
      },
    },
    {
      label: 'Cdn',
      name: 'cdn',
      required: true,
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        maxLength: 100,
        placeHolder: 'Selectionnez',
        // formatOnBlur: true,
        type: [InputType.SELECT],
        width: 200,
      },
    },
    {
      label: 'Qte',
      name: 'qte',
      required: true,
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        maxLength: 4,
        type: [InputType.NUMBER_INTEGER_POS],
        width: 100,
        validates: [
          {
            message: 'Quantité doit être supérieure à 0',
            validateFunction: (value) => isGreatThan(Number(value), 0),
            order: 0,
            realtime: true,
          },
        ],
      },
    },

    {
      label: 'Total pièce',
      name: 'total_piece',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        maxLength: 20,
        type: [InputType.ALL_TEXT],
        width: 120,
        disabled: true,
        validates: [
          {
            order: 0,
            validateFunction: (value) =>
              isLessThan(Number(value), 10000 * 10000 * 10000 + 1),
            message: 'Max = 1.000.000.000.000',
            realtime: true,
          },
        ],
      },
    },
    {
      label: 'Manquants',
      name: 'manquants',
      cInputProps: {
        ...cInputProps,
        className: 'border-children-cell-table text-center w-[150px]',
        cellClass: 'bd-right-cell-table',
        width: 150,
        disabled: true,
        invisibleIfChild: true,
        element: (props) => {
          const cdn = props.line?.find((i: any) => i.name === 'cdn')
          const cdnChosen = cdn?.options?.find(
            (item: any) => item.value === cdn.value
          )
          const isError = Number(props.value) > 0
          return (
            <div
              className={clsx(
                'wrapper-table table-cell border-children-cell-table'
              )}
              style={{ borderRight: '1px solid #e5e5e5' }}
            >
              <div className="flex items-center justify-center">
                <p
                  className={clsx(
                    'text-md w-[150px] text-center',
                    isError ? 'font-bold text-red' : ''
                  )}
                >
                  {isError
                    ? `${props.value} ${cdnChosen.label?.toLowerCase()}${
                        Number(props.value) > 1 ? 's' : ''
                      }`
                    : '-'}
                </p>
              </div>
            </div>
          )
        },
      },
    },
    {
      label: 'Qte confirmée',
      name: 'qte_confirmee',
      required: true,
      cInputProps: {
        ...cInputProps,
        cellClass: 'bd-right-cell-table',
        className: 'border-children-cell-table text-center',
        maxLength: 20,
        width: 200,
        type: [InputType.NUMBER_INTEGER_POS],
        requiredMessage: ' ',
        // disabled: true,
        textStyleFollowValue: (value) => {
          if (value === '') return ''
          if (Number(value) === 0) return 'font-bold text-red'
          return ''
        },
        // element: (props) => (
        //   <div
        //     className="wrapper-table table-cell border-children-cell-table "
        //     style={{ borderRight: '1px solid #e5e5e5' }}
        //   >
        //     <div className="flex items-center justify-center">
        //       <p
        //         className={clsx(
        //           'text-md w-[200px] text-center',
        //           Number(props.value) === 0 ? 'font-bold text-red' : ''
        //         )}
        //       >
        //         {props.value}
        //       </p>
        //     </div>
        //   </div>
        // ),
      },
    },
    {
      label: 'Motif',
      name: 'motif',
      cInputProps: {
        ...cInputProps,
        cellClass: 'bd-right-cell-table',
        className: 'border-children-cell-table text-center',
        required: false,
        disabled: false,
        maxLength: 100,
        // element: () => (
        //   <div
        //     className="wrapper-table table-cell border-children-cell-table "
        //     style={{ borderRight: '1px solid #e5e5e5' }}
        //   >
        //     <div className="flex items-center justify-center">
        //       <p className={clsx('text-md w-[300px] text-center')}>-</p>
        //     </div>
        //   </div>
        // ),
      },
    },
    {
      name: 'mission_id',
      required: false,
      cInputProps: {
        invisible: true,
      },
      isHide: true,
    },
    {
      name: 'is_color',
      required: false,
      cInputProps: {
        invisible: true,
      },
      isHide: true,
    },
    {
      name: 'is_manual_sscc',
      required: false,
      cInputProps: {
        invisible: true,
      },
      isHide: true,
    },
    {
      name: 'is_manual_lot',
      required: false,
      cInputProps: {
        invisible: true,
      },
      isHide: true,
    },
    {
      name: 'is_manual_dluo',
      required: false,
      cInputProps: {
        invisible: true,
      },
      isHide: true,
    },
  ])
  const [data, setData] = useState<DataTableParam[][]>(
    props?.mode === MODE.EDIT
      ? initDataOfDetailTable(props.initData || [])
      : [firstLineData]
  )
  const [invalidList, setInvalidList] = useState<InvalidProperty[][]>([[]])
  const [actions, setActions] = useState<ActionParam[]>([])
  const [listNotFillRequired, setListNotFillRequired] = useState<string[][]>([
    [],
  ])
  const [forceShowWarning, setForceShowWarning] = useState<
    ForceShowWarning[][]
  >([[]])
  const [forceShowError, setForceShowError] = useState<ForceShowError[][]>([[]])
  const [disableLines, setDisableLines] = useState<number[]>([])
  const [hideCols] = useState<string[]>([
    'mission_id',
    'is_color',
    'is_manual_sscc',
    'is_manual_lot',
    'is_manual_dluo',
  ])

  const [resetFlags] = useState<boolean[]>([])
  //const [observeAndTransform, setObserveAndTransform] = useState<ObserveAndTransform[] | undefined>([])
  const [observeAndTransform] = useState<ObserveAndTransform[] | undefined>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isAllowAddNewLine, setIsAllowAddNewLine] = useState<boolean>(false)
  const [forceReset, setForceReset] = useState<ResetProperty[][]>([[]])
  const [isInit, setIsInit] = useState<boolean>(true)
  //const [blockTable, setBlockTable] = useState<boolean>(false)
  const [formatRows] = useState<FormatRowCol[][]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [photos, setPhotos] = useState<number>(props?.valuePhoto || 0)
  const [currentCDNs, setCurrentCDNs] = useState<Conditionnement[]>([])
  useEffect(() => {
    setPhotos(props?.valuePhoto || 0)
  }, [props?.valuePhoto])
  useEffect(() => {
    if (!props?.initData) return
    const forceShowErrorClone = [[]]
    const disabledLinesClone = []
    const listNotFillRequiredClone = [[]]
    const invalidListClone = [[]]
    const forceResetClone = [[]]
    const forceShowWarningClone = [[]]

    for (let i = 0; i < props.initData.length - 1; i++) {
      invalidListClone.push([])
      forceShowErrorClone.push([])
      listNotFillRequiredClone.push([])
      forceResetClone.push([])
      forceShowWarningClone.push([])
    }
    for (let i = 0; i < props.initData.length; i++) disabledLinesClone.push(i)

    setForceShowError(forceShowErrorClone)
    setDisableLines(disabledLinesClone)
    setListNotFillRequired(listNotFillRequiredClone)
    setInvalidList(invalidListClone)
    setForceReset(forceResetClone)
    setForceShowWarning(forceShowWarningClone)
    if (props.onCheckExistWaitingLine) {
      props.onCheckExistWaitingLine(checkExistWaitingLine(props.initData))
    }
  }, [props?.initData])

  useEffect(() => {
    const mode = props?.mode
    const actions: ActionParam[] = []
    if (mode !== MODE.EDIT) {
      actions.push({
        isAllowDelete: true,
        isAllowEdit: false,
        isFillAllRequired: false,
        isForceValid: false,
        isValid: false,
      })
    } else {
      const initData = props?.initData
      if (!initData || isEmpty(initData))
        actions.push({
          isAllowDelete: true,
          isAllowEdit: false,
          isFillAllRequired: false,
          isForceValid: false,
          isValid: false,
        })
      else {
        for (let i = 0; i < initData.length; i++) {
          const currentLine = initData[i]
          let isBlock = currentLine.find(
            (item) => item.name === 'isBlock'
          )?.value
          const cdn: any = currentLine.find((item) => item.name === 'cdn')
          const level = cdn?.options?.[0]?.extraValue
          const parent_id = currentLine.find(
            (item) => item.name === 'parent_id'
          )?.value
          const isSent = currentLine.find(
            (item) => item.name === 'is_color'
          )?.value
          if (!isBlock) isBlock = '0'
          const isEmptyLine = !currentLine.find((item) => item.name === 'id')
            ?.value

          actions.push({
            isAllowDelete: true,
            isAllowEdit: !isEmptyLine || isBlock !== '1',
            isFillAllRequired: true,
            isForceValid: true,
            isValid: true,
            isShowAction: !Boolean(isSent) && (level !== 2 || !parent_id),
            parentLineId: parent_id,
          })
        }
      }
    }
    setActions(actions)
  }, [props?.mode, props?.initData])

  useEffect(() => {
    if (!props?.onChangeInit) return
    props?.onChangeInit(isInit)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInit])

  useEffect(() => {
    if (props?.mode !== MODE.EDIT) return
    if (isEmpty(props?.initData)) setData([firstLineData])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.mode])

  useEffect(() => {
    if (props.mode === MODE.EDIT && !isEmpty(props.initData))
      setIsAllowAddNewLine(true)
    else setIsAllowAddNewLine(false)
  }, [props?.mode, props.initData])

  useEffect(() => {
    if (actions.length === 0) return
    const actionsClone = cloneDeep(actions)
    for (let i = 0; i < invalidList.length; i++) {
      if (!actionsClone[i]) continue
      actionsClone[i].isValid = invalidList[i].length === 0
    }
    for (let i = 0; i < listNotFillRequired.length; i++) {
      if (!actionsClone[i]) continue
      actionsClone[i].isFillAllRequired = listNotFillRequired[i].length === 0
    }
    for (let i = 0; i < data.length; i++) {
      const line = data[i]
      if (!actionsClone[i]) continue
      const isBlock =
        line.find((item) => item.name === 'isBlock')?.value === '1'
      if (isBlock) actionsClone[i].isAllowEdit = false

      const isEmptyLine = !line.find((item) => item.name === 'id')?.value
      if (isEmptyLine) actionsClone[i].isAllowEdit = false
    }
    setActions(actionsClone)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalidList, listNotFillRequired])

  // const calcNewLine = (oldLine: DataTableParam[]): DataTableParam[] => {
  //   const oldNiveau = oldLine.find((item) => item.name === 'niveau')
  //   let newData = cloneDeep(firstLineData)

  //   return newData
  // }

  useEffect(() => {
    if (props?.onCheckExistWaitingLine)
      props.onCheckExistWaitingLine(checkExistWaitingLine(data))
  }, [data])

  const onChangeDataTable = async (
    index: number,
    nameAndValue: NameAndValue
  ) => {
    setIsInit(false)
    if (props?.onCheckValid) props.onCheckValid(false)
    const forceResetClone = cloneDeep(forceReset)
    const onResetInput = (name: string) => {
      const forceResetIndex = forceResetClone[index].findIndex(
        (item) => item.name === name
      )
      const forceShowWarningClone = cloneDeep(forceShowWarning)
      setForceShowWarning(forceShowWarningClone)
      forceShowWarningClone[index] = forceShowWarningClone[index].filter(
        (item) => item.name !== name
      )
      if (forceResetIndex === -1) {
        forceResetClone[index].push({ name: name, value: true })
      } else {
        forceResetClone[index][forceResetIndex].value =
          !forceResetClone[index][forceResetIndex].value
      }
      const valueIndex = currentLine.findIndex((item) => item.name === name)
      if (valueIndex !== -1) currentLine[valueIndex].value = ''
    }

    const onResetForShowError = (name: string, index: number) => {
      forceShowErrorClone[index] = forceShowErrorClone[index]?.filter(
        (item) => item.name !== name
      )
      setForceShowError(forceShowErrorClone)
    }

    //--------------
    if (props.onBackToInit) props.onBackToInit(false)
    const dataClone = cloneDeep(data)
    const forceShowErrorClone = cloneDeep(forceShowError)
    let currentLine = dataClone[index]

    let currentChange = currentLine.find(
      (item) => item.name === nameAndValue.name
    )
    if (!currentChange) return

    currentChange.value = nameAndValue.value

    currentLine = currentLine.filter((item) => item.name !== nameAndValue.name)
    currentLine.push(currentChange)
    const { name, value } = currentChange

    if (name === 'qte_confirmee') {
      const motifIndex = getIndexes('motif', dataClone[index])
      if (motifIndex !== -1) {
        dataClone[index][motifIndex].value = ''
        dataClone[index][motifIndex].disabled = false
      }
    }

    dataClone[index] = currentLine
    setData(dataClone)
  }
  const onCheckInvalid = (
    index: number,
    invalidProperty: InvalidProperty | null
  ) => {
    const inValidListClone = cloneDeep(invalidList)
    if (!invalidProperty) inValidListClone[index] = []
    else {
      const indexOfProperty = inValidListClone[index].findIndex(
        (item) => item.name === invalidProperty?.name
      )
      if (indexOfProperty === -1) inValidListClone[index].push(invalidProperty)
      else inValidListClone[index][indexOfProperty] = invalidProperty
    }
    setInvalidList(inValidListClone)
  }

  const onCheckFillRequired = (index: number, list: string[]) => {
    if (isEmpty(actions)) return
    if (!actions[index]) return
    const actionsClone = cloneDeep(actions)
    const listNotFillRequiredClone = cloneDeep(listNotFillRequired)
    actionsClone[index].isFillAllRequired = isEmpty(list)
    listNotFillRequiredClone[index] = list
    actionsClone[index].isFillAllRequired = list.length === 0
    setListNotFillRequired(listNotFillRequiredClone)
    setActions(actionsClone)
  }

  const onAllowEdit = async (index: number) => {
    let disableLinesClone = cloneDeep(disableLines)
    let actionsClone = cloneDeep(actions)
    let dataClone = cloneDeep(data)
    // debugger

    disableLinesClone = disableLinesClone.filter((item) => item !== index)
    actionsClone[index].isForceValid = false
    actionsClone[index].isAllowEdit = true
    for (let i = 0; i < actionsClone.length; i++) {
      if (i !== index) {
        actionsClone[i].isAllowEdit = false
        actionsClone[i].isAllowDelete = false
      }
    }

    const indexOfMotif = dataClone[index].findIndex(
      (item) => item.name === 'motif'
    )
    if (indexOfMotif !== -1) dataClone[index][indexOfMotif].disabled = true

    const parentId = dataClone[index].find((item) => item.name === 'parent_id')
    dataClone[index] = dataClone[index].map((item) => {
      item.disabled = true
      return item
    })
    if (parentId?.value) {
      const ssccIndex = dataClone[index].findIndex(
        (item) => item.name === 'sscc'
      )
      if (ssccIndex !== -1) {
        const ssccFeature = dataClone[index][ssccIndex]
        if (ssccFeature.refreshAction)
          dataClone[index][ssccIndex].allowRefresh = true
      }
    } else {
      dataClone[index] = dataClone[index].map((item) => {
        if (item.name === 'qte_confirmee') item.disabled = false
        return item
      })
    }

    setActions(actionsClone)
    setDisableLines(disableLinesClone)
    setIsAllowAddNewLine(false)
    setData(dataClone)
    if (props.onCheckValid) props.onCheckValid(false)
    if (props.onEditing) props.onEditing(true)
    // focus in button validate
    focusById(`commandeicon-valid-${index}`, 300)
  }
  const onCheckValidByBtn = (index: number) => {
    let actionsClone = cloneDeep(actions)
    let disableLinesClone = cloneDeep(disableLines)
    let dataClone = cloneDeep(data)
    const id = cloneDeep(data)[index].find((item) => item.name === 'id')?.value

    actionsClone[index].isForceValid = true
    actionsClone = actionsClone.map((item, idx) => {
      item.isAllowDelete = true
      item.isAllowEdit = true
      item.isFillAllRequired = true
      item.isForceValid = true
      item.isValid = true

      return item
    })
    disableLinesClone.push(index)

    const parentId = dataClone[index].find((item) => item.name === 'parent_id')
    if (parentId) {
      dataClone[index] = dataClone[index].map((item) => {
        if (item.name === 'sscc') return item
        item.disabled = true
        return item
      })

      const ssccIndex = dataClone[index].findIndex(
        (item) => item.name === 'sscc'
      )
      if (ssccIndex !== -1) {
        const ssccFeature = dataClone[index][ssccIndex]
        if (ssccFeature.refreshAction)
          dataClone[index][ssccIndex].allowRefresh = false
      }
    }

    if (!parentId?.value) {
      // const oldQteConfirm = getOldQteConfirm(props.initData || [], id || '') || 0;
      const newQteConfirm = Number(
        dataClone[index].find((item) => item.name === 'qte_confirmee')?.value
      )
      const totalDetailLineQteConfirm = getTotalQteConfirmOfLine(
        props.initData || [],
        id
      )
      // const difference = oldQteConfirm - newQteConfirm;
      const difference = totalDetailLineQteConfirm - newQteConfirm

      dataClone = dataClone.map((line) => uniqBy(line, 'name'))
      dataClone =
        getDetailLinesNeedAndNotNeedToRemove(
          difference,
          props.clientRule || ClientRule.OTHER,
          cloneDeep(data),
          dataClone[index] || [],
          cloneDeep(props.initData || [])
        ) || []

      actionsClone = lockLineAfterConfirm(
        dataClone,
        cloneDeep(actionsClone),
        index,
        id
      )

      for (let i = 0; i < actionsClone.length; i++) {
        if (!disableLinesClone.includes(i)) disableLinesClone.push(i)
      }
    }
    for (let i = 0; i < dataClone.length; i++) {
      const line = dataClone[i]
      if (!actionsClone[i]) continue
      const isBlock =
        line.find((item) => item.name === 'isBlock')?.value === '1'
      actionsClone[i].isAllowEdit = !isBlock
    }

    dataClone = mapSsccMotifToParentLine(dataClone)

    dataClone = mapRuptureCheckLotToMainLine(dataClone)
    dataClone = dataClone.map((line) => {
      line = uniqBy(line, 'name')
      return line
    })
    if (props?.onChangeData) props.onChangeData(dataClone)
    if (props?.onCheckExistWaitingLine)
      props?.onCheckExistWaitingLine(checkExistWaitingLine(dataClone))
    setActions(actionsClone)
    setDisableLines(disableLinesClone)
    setIsAllowAddNewLine(true)
    setData(dataClone)
    if (props.onCheckValid) props.onCheckValid(true)
    if (props.onEditing) props.onEditing(false)
    if (props.onCheckExistWaitingLine) {
      props.onCheckExistWaitingLine(checkExistWaitingLine(dataClone))
    }
    // focus in edit button
    focusById(`commandeicon-edit-${index}`, 300)
  }

  const onRefresh = (nameAndValue: NameAndValue, id: string) => {
    let dataClone = cloneDeep(data)
    const indexLine = dataClone.findIndex(
      (line) => line.find((item) => item.name === 'id')?.value === id
    )
    if (indexLine === -1) return
    const currentLine = dataClone[indexLine]
    const ssccIndex = currentLine.findIndex((item) => item.name === 'sscc')
    const motifIndex = currentLine.findIndex((item) => item.name === 'motif')
    if (ssccIndex === -1) return

    const mustRefresh = data[indexLine][ssccIndex].mustRefresh

    dataClone[indexLine][ssccIndex].mustRefresh = !mustRefresh

    // enable motif when refresh sscc, disable motif when un-refresh sscc
    if (!dataClone[indexLine][ssccIndex].mustRefresh) {
      dataClone[indexLine][motifIndex].disabled = true
      dataClone[indexLine][motifIndex].value = ''
    } else {
      dataClone[indexLine][motifIndex].disabled = false
      dataClone[indexLine][motifIndex].value = ''
    }
    //---------------------------------------------------------------------
    setData(dataClone)
  }

  function getNextFocusIds() {
    let nextId = ''
    for (let i = 0; i < data.length; i++) {
      nextId = `${nextId}, commandeqte_confirmee-${i}, commandeicon-edit-${i}`
    }
    return `${nextId}, ${commandeKey['envoyer-mission']}, ${commandeKey['code-nom-transporter']}, ${commandeKey['code-nom-destinataire']}, ${commandeKey['annuler']}, ${commandeKey['Sauvegarder']}`
  }

  function onChangeForcerDluo(value: boolean) {
    if (props.onChangeForcerDluo) props.onChangeForcerDluo(value)
  }

  return (
    <SectionLayout
      title="Détail"
      leftAction={
        <div className="ml-4 flex detail-mep">
          <Form.Item label="" name="force_dlou" valuePropName="force_dlou">
            <CSwitch
              id={commandeKey['Forcer-la-DLUO']}
              className="switch-inside-form-item"
              checked={cmdResponse?.force_dlou}
              nextId={getNextFocusIds()}
              onChange={onChangeForcerDluo}
              defaultChecked={false}
            />
          </Form.Item>
          <span className="pt-1 text-[#20458F] text-base">Forcer la DLUO</span>
        </div>
      }
      alert={props?.alert}
    >
      <Spin spinning={isLoading}>
        <div className="overflow-y-hidden pb-2  detail-scroll box-input-font-size-bigger">
          <ITable
            name="commande"
            blockTable={block}
            resetFlags={resetFlags}
            formatCols={formatCols}
            formatRows={formatRows}
            header={header}
            data={data}
            actions={actions}
            disabledLines={disableLines}
            onChangeDataTable={onChangeDataTable}
            onCheckInvalid={onCheckInvalid}
            onCheckFillRequired={onCheckFillRequired}
            forceShowError={forceShowError}
            forceShowWarning={forceShowWarning}
            hideCols={hideCols}
            observeAndTransform={observeAndTransform}
            forceReset={forceReset}
            inTwoGradeTable
            lineClass={getLineClass}
            onRefresh={onRefresh}
            actionStyle=""
            actionNode={(
              index: number,
              actionParam?: ActionParam
            ): ReactNode => {
              const {
                isValid = false,
                isFillAllRequired = false,
                isAllowDelete = true,
                isForceValid = false,
                isAllowEdit = true,
                isShowAction = true,
              } = actionParam || {
                isValid: false,
                isFillAllRequired: false,
                isAllowDelete: true,
                isForceValid: false,
                isAllowEdit: true,
                isShowAction: true,
              }
              return (
                <div>
                  {isShowAction && (
                    <Row
                      gutter={8}
                      align="middle"
                      className="flex items-center justify-center"
                    >
                      <Col span={12}>
                        {(!isFillAllRequired || !isValid) && (
                          <i className="icon-valid-disable"></i>
                        )}
                        {isFillAllRequired && isValid && !isForceValid && (
                          <i
                            onClick={() => onCheckValidByBtn(index)}
                            className="icon-valid focus:rounded-2xl"
                            data-previous-id={`commandeqte_confirmee-${index}`}
                            data-next-id={`commandeicon-edit-${index + 1}, ${
                              commandeKey['envoyer-mission']
                            }`}
                            id={`commandeicon-valid-${index}`}
                            tabIndex={0}
                          />
                        )}
                        {!block &&
                          isFillAllRequired &&
                          isValid &&
                          isForceValid &&
                          isAllowEdit && (
                            <i
                              className="icon-edit"
                              data-previous-id={`commandeqte_confirmee-${index}, commandeicon-edit-${
                                index - 1
                              }, commandeicon-valid-${index - 1}, ${
                                commandeKey['Forcer-la-DLUO']
                              }`}
                              data-next-id={`commandeicon-edit-${index + 1}, ${
                                commandeKey['envoyer-mission']
                              }, ${commandeKey['code-nom-transporter']}, ${
                                commandeKey['code-nom-destinataire']
                              }`}
                              id={`commandeicon-edit-${index}`}
                              tabIndex={0}
                              onClick={() => onAllowEdit(index)}
                            />
                          )}
                        {(block ||
                          (isFillAllRequired &&
                            isValid &&
                            isForceValid &&
                            !isAllowEdit)) && (
                          <i className="icon-edit-disable" />
                        )}
                      </Col>
                    </Row>
                  )}
                </div>
              )
            }}
          />
        </div>
      </Spin>
    </SectionLayout>
  )
}

export default DetailSectionMEP
