import SectionLayout from '../../../stockscreen/component/SectionLayout'
import { CommandeType } from '../../../../../enum/CommandeType'
import React, { FC } from 'react'
import { CommandeResponse } from '../../../../../models'
import { MissionType } from '../../../../../enum/MissionType'
import { t } from 'i18next'
import { StatusCommande } from '../../../../../enum/StatusCommande'
import UserIcon from '../../../../../assets/icons/UserIcon'
import HourGlass from '../../../../../assets/icons/HourGlass'
import ConfirmedIcon from '../../../../../assets/icons/ConfirmedIcon'

const missionTypeDisplay = (type?: MissionType) => {
  switch (type) {
    case MissionType.ChargementMission:
      return t('chargement')
    case MissionType.PickingType:
      return t('picking_mission')
    case MissionType.PreparationMission:
      return t('preparation')
    case MissionType.ReapproMission:
      return t('reappro')
    default:
      return ''
  }
}

const missionStatusDisplay = (status?: StatusCommande) => {
  switch (status) {
    case StatusCommande.AssignedCommande:
    case StatusCommande.MissionAssigned:
      return <UserIcon />
    case StatusCommande.InProgressCommande:
    case StatusCommande.MissionInprogress:
      return <HourGlass />
    case StatusCommande.PickupCommande:
    case StatusCommande.MissionDone:
      return <ConfirmedIcon />
    default:
      return ''
  }
}

const MissionList: FC<{
  commandeType: string
  cmdResponse?: CommandeResponse
}> = ({ commandeType, cmdResponse }) => {
  const sortMission = cmdResponse?.mission
    // remove mission which are reappropick missions
    ?.filter(
      (item) =>
        !item.nom.includes('RAP') && (item.manager_by || item.assignee_by)
    )
    ?.sort(function (a, b) {
      const createTimeA = a.created_at
      const createTimeB = b.created_at
      if (!createTimeA || !createTimeB) {
        return 0
      } else if (createTimeA > createTimeB) {
        return -1
      } else if (createTimeA < createTimeB) {
        return 1
      } else {
        // valid time must be equal
        return 0
      }
    })

  const displayQuai = () => {
    //quai is the current location of mission
    const stock = cmdResponse?.com_article?.find(
      (el) => el.stock && el.location_id === el.stock.cur_location_id
    )?.stock

    const level = stock?.cur_level === -1 ? '0' : stock?.cur_level
    const position =
      stock?.cur_location_code === '00' ? '0' : stock?.cur_location_code[0]
    const zone = stock?.cur_zone_code
    const aisle = stock?.cur_location_aisle
    const bay = stock?.cur_location_bay

    if (!stock) return '-'
    if (!level || !position || !zone || !aisle || !bay) return '-'
    else return `${zone}${aisle}${bay}${level}${position}`
  }

  return (
    <SectionLayout title="Mission">
      <table className="table-document">
        <thead>
          <tr>
            <th className="form-label">{t('status')}</th>
            <th className="form-label">{t('mission')}</th>
            <th className="form-label">{t('type')}</th>
            <th className="form-label">{t('responsible')}</th>
            <th className="form-label">{t('stockist(s)')}</th>
            <th className="form-label">{t('dock')}</th>
            {commandeType === CommandeType.Envoyer_en_chargement && (
              <th className="form-label">{t('truck')}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {sortMission?.map((mission, index) => {
            return (
              <tr key={mission.id}>
                <td>{missionStatusDisplay(mission.status_code)}</td>
                <td>{mission.nom}</td>
                <td>{missionTypeDisplay(mission.mission_type)}</td>
                <td>{mission.manager_by || '-'}</td>
                <td>{mission.assignee_by || '-'}</td>
                {index === 0 && (
                  <td rowSpan={cmdResponse?.mission?.length}>
                    {displayQuai()}
                  </td>
                )}
                {commandeType === CommandeType.Envoyer_en_chargement && (
                  <td>{mission.truck_number || '-'}</td>
                )}
              </tr>
            )
          })}
        </tbody>
      </table>
    </SectionLayout>
  )
}

export default MissionList
