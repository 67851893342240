import './Commande.scss'
import { Button, Form, Input, Modal, notification, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import clsx from 'clsx'
import { t } from 'i18next'
import { cloneDeep, uniq } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { RootState } from '../../../../../app/store'
import CLOSE_ICON from '../../../../../assets/images/close-success.png'
import ERROR from '../../../../../assets/images/error.png'
import SUCCESS from '../../../../../assets/images/success.png'
import CustomPopup from '../../../../../components/Common/CustomPopup/CustomPopup'
import DisplayDocumentsCmd, {
  FileInformationType,
} from '../../../../../components/Common/Documents/DisplayDocumentsCmd'
import Loading from '../../../../../components/Common/Loading/Loading'
import {
  default as Define,
  default as define,
} from '../../../../../constants/define'
import { StatusAPI } from '../../../../../enum'
import { ClientRule } from '../../../../../enum/ClientRule'
import { CommandeScene } from '../../../../../enum/CommandeScene'
import { CommandeType } from '../../../../../enum/CommandeType'
import { DocumentStatus } from '../../../../../enum/DocumentStatus'
import { MissionType } from '../../../../../enum/MissionType'
import { MODE } from '../../../../../enum/mode'
import { popupType } from '../../../../../enum/popupType'
import { sourceType } from '../../../../../enum/sourceType'
import { StatusCommande } from '../../../../../enum/StatusCommande'
import useCommandeEdit from '../../../../../hook/commande/useCommandeEdit'
import { focusById } from '../../../../../hook/usePressTab'
import commandeApi from '../../../../../http/commandeApi'
import {
  CommandeEnvoyerMissionNewPayload,
  FileResponse,
} from '../../../../../models'
import { clearCurrentDestinataire } from '../../../../../redux/reducers/destinataireSlice'
import { clearCurrentTransporteur } from '../../../../../redux/reducers/transporteurSlice'
import { getCurrentFiltersText, removeTones } from '../../../../../utils'
import {
  convertSentPreparationDataTableToAPI,
  getNewFile,
} from '../../../../../utils/commande/convertDataTabletoAPI'
import SuiviCommande from '../../../commande/SuiviCommande'
import FormDestination from '../../../components/commande/FormDestination'
import FormLivraison from '../../../components/commande/FormLivraison'
import SectionLayout from '../../../stockscreen/component/SectionLayout'
import { ModalName } from '../../customModalSlice'
import DetailSectionMEP from './DetailSectionMEP'
import {
  decideStatusCodeWhenClickEnvoyerEnMission,
  decideStatusCodeWhenClickRemetre,
  mapDescriptionJsonToProperty_comFiles,
  mappingDetailData,
  redirectToCmdScreen,
  setStateForFileWhenAnnulerMise,
  setStateForFileWhenEnvoyePR,
  setStateForFileWhenMeter,
  updateManquantsRightBefore_MetreOrRemetre,
  uploadNonList,
  uploadPickingList,
} from './func'
import MissionList from './MissionList'
import { EtaCommande } from '../../../../../enum/EtaCommande'

interface ActionProps {
  onClickPlusBtn?(): void

  isShowIconPlus?: boolean
}

interface CommandeMisEnPreparationProps {
  statusCode?: StatusCommande
  commandeType?: CommandeType
}

const Action = ({
  isShowIconPlus = true,
  onClickPlusBtn = () => {},
}: ActionProps) => {
  const onClickPlus = (): void => {
    if (onClickPlusBtn) onClickPlusBtn()
  }

  return (
    <div className="flex items-center gap-3">
      {isShowIconPlus && (
        <i className={'icon-add-plus'} onClick={onClickPlus}></i>
      )}
    </div>
  )
}

const CommandeMisEnPreparation = (props: CommandeMisEnPreparationProps) => {
  const commandeKey = useSelector((state: RootState) => state.selector).data
    .commande
  const { commandeType = CommandeType.OTHER } = props
  const [searchParams, setSearchParams] = useSearchParams()
  const params = useParams()
  const pageIndex = Number(searchParams.get('page-index')) || 1
  const pageSize = Number(searchParams.get('page-size')) || 25
  const count = Number(searchParams.get('count')) || 0
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [openPopupImport, setOpenPopupImport] = useState<boolean>(false)
  const [errorClientCode, setErrorClientCode] = useState<string>('')
  const [isOpenModalConfirmCancel, setIsOpenModalConfirmCancel] =
    useState<boolean>(false)
  const [
    isOpenModalConfirmModifyDocument,
    setIsOpenModalConfirmModifyDocument,
  ] = useState<boolean>(false)
  const [isOpenModalConfirmModifyTable, setIsOpenModalConfirmModifyTable] =
    useState<boolean>(false)
  const [isOpenModalDeleteCommande, setIsOpenModalDeleteCommande] =
    useState<boolean>(false)
  const [checkExistWaitingLine, setCheckExistWaitingLine] =
    useState<boolean>(false)

  const {
    form,
    loading,
    initLoading,
    gln,
    displayRelay,
    setSubmitted,
    isValidDetail,
    cmdResponse,
    setLoading,
    detailData,
    detailDataFromApi,
    attDestinataire,
    attTransporteur,
    cmdFile,
    setCmdFile,
    oldCmdFile,
    fileIdsDelete,
    detailLineDeletedIds,
    onChangeDataDetail,
    onCheckValid,
    isCreateTransporteur,
    setIsCreateTransporteur,
    isEmptyTransporteur,
    onChangeTransporteur,
    isCreateDestinataire,
    setIsCreateDestinataire,
    isEmptyDestinataire,
    onChangeDestinataire,
    currentTransporteur,
    currentDestinataire,
    dataDestinataireAdresses,
    dataDestinataireContact,
    changedDetailData,
    handleAttDestinataire,
    handleClose,
    setChangedDetailData,
    checkLastest,
    setDetailData,
    onChangeForcerDluo,
    setCmdResponse,
  } = useCommandeEdit()
  // set init data for detail table
  useEffect(() => {
    setDetailData(detailDataFromApi)
  }, [detailDataFromApi])

  const disabledCommentaire =
    cmdResponse?.scene === CommandeScene.TotalScene ||
    cmdResponse?.scene === CommandeScene.ChargementScene
  const [isAllowEnvoyerEnMission, setIsAllowEnvoyerEnMission] =
    useState<boolean>(false)
  const dispatch = useDispatch()
  const isComplete = props.commandeType === CommandeType.Envoye_en_mission
  const isChargement = props.commandeType === CommandeType.Envoyer_en_chargement
  const [isEditingDetailTable, setIsEditingDetailTable] =
    useState<boolean>(false)

  const isAtLeastOneFileSendOnMission = cmdFile.some(
    (el) => JSON.parse(el.description || '{}').sendMissionPrAt
  )
  const [isExistWaitingLine, setIsExistWaitingLine] = useState<Boolean>(false)

  const checkNotExistAutoFindLine =
    cmdResponse?.com_article?.filter(
      (el) => el.source === sourceType.AUTO && !el.is_color
    ).length === 0

  const checkLastMission = cmdResponse?.com_article
    ?.filter((el) => el.source === sourceType.AUTO)
    .every(
      (el) =>
        el.status_code === StatusCommande.PickupCommande ||
        el.status_code === StatusCommande.MissionUnassigned ||
        el.status_code === StatusCommande.MissionAssigned ||
        el.status_code === StatusCommande.MissionInprogress ||
        el.status_code === StatusCommande.MissionPending ||
        el.status_code === StatusCommande.MissionDone
    )

  //get files change from document
  const onChangeDataFile = async (data: FileResponse[], id?: string) => {
    setLoading(true)
    try {
      const response = (
        await commandeApi.getCommandeById({ id: params.id || '' })
      ).data.entry
      for (let i = 0; i < data.length; i++) {
        data[i].statut = undefined
        delete data[i]['cancel_at']
      }
      await commandeApi.updateCommande({
        entry: {
          ...response,
          modification_by: localStorage.getItem(Define.USERNAME) || '',
          modificationer_id: localStorage.getItem(Define.USER_ID) || '',
          com_file: data.length > 0 ? data : undefined,
          com_article: undefined,
          com_livraison: undefined,
          com_preparation: undefined,
          des_addresse: undefined,
          des_contact: undefined,
          destinataire: undefined,
          tran_addresse: undefined,
          tran_contact: undefined,
          transporteur: undefined,
        },
        details_delete: { id: [] },
        files_delete: { id: id ? [id] : [] },
      })
      const res = (await commandeApi.getCommandeById({ id: params.id || '' }))
        .data.entry
      const sortFile = mapDescriptionJsonToProperty_comFiles(
        res.com_file?.sort(
          (a, b) => (b?.created_at || 0) - (a?.created_at || 0)
        ) || []
      )
      setCmdResponse(res)
      setCmdFile(sortFile || [])
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const onCheckExistWaitingLine = (isExist: boolean) => {
    setIsExistWaitingLine(isExist)
  }

  //click mise en preparation
  const miseEnPreparation = async () => {
    if (detailData.length === 0 && isValidDetail) return
    if (!isValidDetail) return
    setLoading(true)

    let detailDataMapping = convertSentPreparationDataTableToAPI(
      detailData,
      cmdResponse?.com_article || []
    )

    const shouldDeleteLines = detailLineDeletedIds.filter(
      (lineId) =>
        detailDataMapping.find((i) => i.id === lineId)?.conditionement_level ===
        define.MAX_CDN_LEVEL
    )

    const articleResponse = cmdResponse?.com_article || []
    const detailDataMappingChild = detailDataMapping.filter(
      (val, index) =>
        articleResponse.find((item) => item.id === val.id)?.parent_article_id &&
        val.id
    )
    detailDataMapping = detailDataMapping.filter(
      (val, index) =>
        !articleResponse.find((item) => item.id === val.id)?.parent_article_id
    )

    for (let i = 0; i < detailDataMapping.length; i++) {
      const articleCorr = articleResponse.find(
        (item) => item.id === detailDataMapping[i].id
      )
      if (!articleCorr) continue
      detailDataMapping[i].created_by = cmdResponse?.created_by
      detailDataMapping[i].creater_id = cmdResponse?.creater_id
      detailDataMapping[i].modification_by =
        localStorage.getItem(Define.USERNAME) || ''
      detailDataMapping[i].modificationer_id =
        localStorage.getItem(Define.USER_ID) || ''
      detailDataMapping[i].status_code = cmdResponse?.status_code
      detailDataMapping[i].is_manual_dluo = articleCorr?.is_manual_dluo
      detailDataMapping[i].is_manual_sscc = articleCorr?.is_manual_sscc
      detailDataMapping[i].is_manual_lot = articleCorr?.is_manual_lot
      detailDataMapping[i].commande_created_at = cmdResponse?.created_at
      detailDataMapping[i].active_lot = true
    }

    const newStatusCodeOfCmd = decideStatusCodeWhenClickRemetre(
      detailDataMapping,
      detailDataMappingChild
    )

    try {
      const com_file = setStateForFileWhenMeter(cmdFile).map((item) => {
        delete item.statut
        delete item.cancel_at
        delete item.showEnvoyer
        return item
      })

      detailDataMapping =
        updateManquantsRightBefore_MetreOrRemetre(detailDataMapping)

      let sscc_block: string[] = []
      detailDataMapping.forEach((line) =>
        (line?.sscc_block || []).forEach((sscc) => sscc_block.push(sscc || ''))
      )
      detailDataMapping = detailDataMapping.map((item) => {
        if (item.sscc_block) delete item.sscc_block
        return item
      })
      sscc_block = sscc_block.concat(cmdResponse?.sscc_block || [])
      sscc_block = uniq(sscc_block)
      const bodyForValidCmd = {
        entry: {
          nom: form.getFieldValue('nom'),
          nom_client: form.getFieldValue('nom_client'),
          priorite: form.getFieldValue('priorite'),
          force_dlou:
            form.getFieldValue('force_dlou') !== undefined
              ? form.getFieldValue('force_dlou')
              : Boolean(cmdResponse?.force_dlou),
          id: cmdResponse?.id,
          client_code_nom: cmdResponse?.client_code_nom,
          warehouse_code_nom: cmdResponse?.warehouse_code_nom,
          company_code_nom: cmdResponse?.company_code_nom,
          chargement_at: form.getFieldValue('chargement_at')
            ? form.getFieldValue('chargement_at').unix()
            : undefined,
          livraison_at: form.getFieldValue('livraison_at')
            ? form.getFieldValue('livraison_at').unix()
            : undefined,
          destinataire_id: attDestinataire?.destinataire_id,
          des_addresse_id: attDestinataire?.address_id,
          des_contact_id: attDestinataire?.contact_id,
          transporteur_id: attTransporteur?.transporteur_id,
          tran_addresse_id: attTransporteur?.address_id,
          tran_contact_id: attTransporteur?.contact_id,
          imperatif: form.getFieldValue('imperatif'),
          relay_detail: form.getFieldValue('relay_detail'),
          status_code: cmdResponse?.status_code,
          created_at: cmdResponse?.created_at,
          created_by: cmdResponse?.created_by,
          creater_id: cmdResponse?.creater_id,
          modification_by: localStorage.getItem(Define.USERNAME) || '',
          modificationer_id: localStorage.getItem(Define.USER_ID) || '',
          photos: cmdResponse?.photos,
          com_file: com_file,
          com_livraison: form.getFieldValue('com_livraison'),
          com_preparation: form.getFieldValue('com_preparation'),
          com_article: detailDataMapping,
          valid_times: (cmdResponse?.valid_times || 0) + 1,
          updated_at: cmdResponse?.updated_at,
          sscc_block,
        },
        details_delete: { id: shouldDeleteLines },
        files_delete: { id: fileIdsDelete },
      }

      if (newStatusCodeOfCmd)
        bodyForValidCmd.entry.status_code = newStatusCodeOfCmd
      await commandeApi.validCommande(bodyForValidCmd)

      let response = await commandeApi.getCommandeById({
        id: cmdResponse?.id || '',
      })
      let newResponse = cloneDeep(response.data.entry)

      let entry = response.data.entry

      if (
        entry.status_code &&
        [StatusCommande.CreatedCommande, StatusCommande.ValidCommande].includes(
          entry.status_code
        )
      ) {
        await uploadPickingList(cloneDeep(entry), DocumentStatus.Ajour)
        await uploadNonList(cloneDeep(entry), DocumentStatus.DASH)
      }
      setChangedDetailData(false)
      setIsAllowEnvoyerEnMission((entry.com_article?.length || 0) > 0)

      setLoading(false)
      let linkToRedirect = redirectToCmdScreen(
        newResponse,
        cmdResponse,
        pageIndex,
        pageSize
      )
      if (!linkToRedirect) {
        linkToRedirect += `${pathname}?count=${count + 1}`
      } else linkToRedirect += `&count=${count + 1}`
      navigate(linkToRedirect, { replace: true })
    } catch (e: any) {
      setLoading(false)
      if (e.response.data.error.includes('Update time is expried')) {
        setIsOpenModalConfirmModifyTable(true)
      } else if (e.response.data.error.includes('record not found')) {
        setIsOpenModalDeleteCommande(true)
      } else {
        notification.open({
          className: 'noti noti-error',
          message: (
            <div className="flex items-center">
              <img src={ERROR} alt="error" width={50} className="mr-2.5" />
              Une erreur s'est produite. Merci de réessayer ultérieurement
            </div>
          ),
          placement: 'topRight',
          closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
          duration: 3,
        })
        navigate(
          `/gestion-de-stock/commande/commande-preparation/${
            cmdResponse?.id
          }?page-index=${pageIndex}&page-size=${pageSize}&isError=true${getCurrentFiltersText()}`
        )
      }
    }
  }

  //click sauvegarder
  const onFinishForm = async (value: any) => {
    setSubmitted(true)
    if (!isValidDetail) return
    if (cmdResponse?.photos && cmdResponse?.photos > 20) return
    setLoading(true)
    // delete search fields
    delete value.destinataire
    delete value.transporteur
    const shouldDeleteLines = detailLineDeletedIds.filter(
      (lineId) =>
        detailDataMapping.find((i) => i.id === lineId)?.conditionement_level ===
        define.MAX_CDN_LEVEL
    )
    let detailDataMapping = convertSentPreparationDataTableToAPI(
      detailData,
      cmdResponse?.com_article || []
    )
    const articleResponse = cmdResponse?.com_article || []

    detailDataMapping = detailDataMapping.filter(
      (item) => item.source === sourceType.MANUAL
    )

    for (let i = 0; i < detailDataMapping.length; i++) {
      const id = detailDataMapping[i].id
      const articleCorrespond = articleResponse.find((item) => item.id === id)
      detailDataMapping[i].created_by = cmdResponse?.created_by
      detailDataMapping[i].creater_id = cmdResponse?.creater_id
      detailDataMapping[i].modification_by =
        localStorage.getItem(Define.USERNAME) || ''
      detailDataMapping[i].modificationer_id =
        localStorage.getItem(Define.USER_ID) || ''
      detailDataMapping[i].status_code = cmdResponse?.status_code
      detailDataMapping[i].is_manual_dluo = articleCorrespond?.is_manual_dluo
      detailDataMapping[i].is_manual_sscc = articleCorrespond?.is_manual_sscc
      detailDataMapping[i].is_manual_lot = articleCorrespond?.is_manual_lot
      detailDataMapping[i].stock = articleCorrespond?.stock
      detailDataMapping[i].active_lot = true
      detailDataMapping[i].commande_created_at = cmdResponse?.created_at
    }

    let newCmdFile = getNewFile(cmdFile, oldCmdFile)
    if (newCmdFile)
      for (let i = 0; i < newCmdFile.length; i++) {
        newCmdFile[i].statut = undefined
      }

    const sscc_block: string[] = []
    detailDataMapping.forEach((line) =>
      (line?.sscc_block || []).forEach((sscc) => sscc_block.push(sscc || ''))
    )
    detailDataMapping = detailDataMapping.map((item) => {
      if (item.sscc_block) delete item.sscc_block
      return item
    })
    try {
      await commandeApi.updateCommande({
        entry: {
          ...value,
          id: cmdResponse?.id,
          created_at: cmdResponse?.created_at,
          active_lot: cmdResponse?.active_lot,
          force_dlou:
            form.getFieldValue('force_dlou') !== undefined
              ? form.getFieldValue('force_dlou')
              : Boolean(cmdResponse?.force_dlou),
          client_code_nom: cmdResponse?.client_code_nom,
          warehouse_code_nom: cmdResponse?.warehouse_code_nom,
          company_code_nom: cmdResponse?.company_code_nom,
          chargement_at: value.chargement_at
            ? value.chargement_at.unix()
            : undefined,
          livraison_at: value.livraison_at
            ? value.livraison_at.unix()
            : undefined,
          destinataire_id: attDestinataire?.destinataire_id,
          des_addresse_id: attDestinataire?.address_id,
          des_contact_id: attDestinataire?.contact_id,
          transporteur_id: attTransporteur?.transporteur_id,
          tran_addresse_id: attTransporteur?.address_id,
          tran_contact_id: attTransporteur?.contact_id,
          imperatif: value.imperatif,
          status_code: StatusCommande.CreatedCommande,
          created_by: cmdResponse?.created_by,
          creater_id: cmdResponse?.creater_id,
          modification_by: localStorage.getItem(Define.USERNAME),
          modificationer_id: localStorage.getItem(Define.USER_ID),
          photos: cmdResponse?.photos,
          com_file: newCmdFile,
          com_livraison: form.getFieldValue('com_livraison'),
          com_preparation: form.getFieldValue('com_preparation'),
          com_article: detailDataMapping,
          scene: CommandeScene.MiseScene,
          valid_times: cmdResponse?.valid_times || 0,
          updated_at: cmdResponse?.updated_at,
          sscc_block,
        },
        details_delete: { id: shouldDeleteLines },
        files_delete: { id: fileIdsDelete },
      })

      setLoading(false)
      notification.open({
        className: 'noti noti-success',
        message: (
          <div className="flex items-center">
            <img src={SUCCESS} alt="success" width={50} className="mr-2.5" />
            Succès: Commande enregistrée
          </div>
        ),
        placement: 'topRight',
        closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
        duration: 3,
      })
      // reload current list page
      handleClose()
    } catch (error: any) {
      setLoading(false)

      if (error.response.data.error.includes('record not found')) {
        setIsOpenModalDeleteCommande(true)
      } else if (error.response.data.error.includes('Update time is expried')) {
        setIsOpenModalConfirmModifyTable(true)
      } else
        notification.open({
          className: 'noti noti-error',
          message: (
            <div className="flex items-center">
              <img src={ERROR} alt="error" width={50} className="mr-2.5" />
              Une erreur s'est produite. Merci de réessayer ultérieurement
            </div>
          ),
          placement: 'topRight',
          closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
          duration: 3,
        })
    }
  }

  useEffect(() => {
    const link = redirectToCmdScreen(
      cmdResponse,
      cmdResponse,
      pageIndex,
      pageSize
    )
    if (link === '') return
    if (
      link.includes('commande-preparation') &&
      commandeType === CommandeType.Mis_en_preparation
    )
      return
    if (
      link.includes('commande-envoye-en-mission') &&
      commandeType === CommandeType.Envoye_en_mission
    )
      return
    if (
      link.includes('commande-envoyer-en-chargement') &&
      commandeType === CommandeType.Envoyer_en_chargement
    )
      return
    else navigate(link, { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmdResponse])

  useEffect(() => {
    if (!cmdResponse) {
      setIsAllowEnvoyerEnMission(false)
      return
    }
    const pickingList = cmdResponse.com_article?.filter(
      (el) =>
        el.status_code &&
        [
          StatusCommande.SendOnMissionCommande,
          StatusCommande.ValidCommande,
        ].includes(el.status_code) &&
        el.sscc &&
        el.source === sourceType.AUTO
    )
    setIsAllowEnvoyerEnMission((pickingList?.length || 0) > 0)
  }, [cmdResponse])

  const onCancelCmd = async () => {
    setIsOpenModalConfirmCancel(true)
    focusById(commandeKey['annuler'], 500)
  }

  const confirmCancel = async () => {
    setLoading(true)
    setIsOpenModalConfirmCancel(false)
    try {
      const status = await checkLastest({
        id: params.id || '',
        updated_at: cmdResponse?.updated_at || 0,
      })

      if (!status) {
        setIsOpenModalConfirmModifyTable(true)
      } else {
        const cmdResponseClone = cloneDeep(cmdResponse)

        let {
          com_article,
          client_ob,
          des_addresse,
          des_contact,
          destinataire,
          tran_addresse,
          tran_contact,
          transporteur,
          com_livraison,
          com_preparation,
          mission,
          nom,
          ...rest
        } = cmdResponseClone || {}

        await commandeApi.cancelCommande({
          entry: {
            ...rest,
            com_file: setStateForFileWhenAnnulerMise(rest.com_file || []),
            scene: CommandeScene.EditScene,
            valid_times: 0,
          },
        })

        let linkToRedirect = redirectToCmdScreen(
          cmdResponse,
          cmdResponse,
          pageIndex,
          pageSize
        )

        if (!linkToRedirect) {
          linkToRedirect += `${pathname}?count=${count + 1}`
        } else linkToRedirect += `&count=${count + 1}`
        navigate(linkToRedirect, { replace: true })
      }
    } catch (e: any) {
      if (e.response.data.error.includes('record not found')) {
        setIsOpenModalDeleteCommande(true)
      } else if (e.response.data.error.includes('Update time is expried')) {
        setIsOpenModalConfirmModifyTable(true)
      } else
        notification.open({
          className: 'noti noti-error',
          message: (
            <div className="flex items-center">
              <img src={ERROR} alt="error" width={50} className="mr-2.5" />
              Une erreur s'est produite. Merci de réessayer ultérieurement
            </div>
          ),
          placement: 'topRight',
          closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
          duration: 3,
        })
    } finally {
      setLoading(false)
    }
  }

  const notConfirmCancel = () => {
    setIsOpenModalConfirmCancel(false)
  }

  const onEnvoyerEnMission = async (files: Array<FileInformationType>) => {
    setSubmitted(false)

    if (detailData.length === 0 && !isValidDetail) return

    setLoading(true)

    const detailDataMapping = mappingDetailData(
      detailData,
      detailDataFromApi,
      cmdResponse
    )

    const newStatusCodeOfCmd = decideStatusCodeWhenClickEnvoyerEnMission(
      detailDataMapping,
      detailLineDeletedIds
    )

    const cmdResponseClone = cloneDeep(cmdResponse)
    let {
      com_article,
      client_ob,
      des_addresse,
      des_contact,
      destinataire,
      tran_addresse,
      tran_contact,
      transporteur,
      com_livraison,
      com_preparation,
      mission,
      nom,
      ...rest
    } = cmdResponseClone || {}

    const bodySendOnMission: CommandeEnvoyerMissionNewPayload = {
      ...rest,
      com_file: setStateForFileWhenEnvoyePR(rest?.com_file || [], files),
      modification_by: localStorage.getItem(Define.USERNAME) || '',
      modificationer_id: localStorage.getItem(Define.USER_ID) || '',
      validation_by: localStorage.getItem(Define.USERNAME) || '',
      validationer_id: localStorage.getItem(Define.USER_ID) || '',
      validation_at: new Date().getTime(),
      priorite: rest?.priorite || 5,
      company_code_nom: rest?.company_code_nom || '',
      warehouse_code_nom: rest?.warehouse_code_nom || '',
      client_code_nom: rest?.client_code_nom || '',
      status_code: newStatusCodeOfCmd,
      scene:
        rest?.status_code === StatusCommande.SendOnMissionCommande
          ? CommandeScene.TotalScene
          : CommandeScene.MiseScene,
    }
    try {
      await commandeApi.envoyerEnMissionNew(bodySendOnMission)

      let linkToRedirect = redirectToCmdScreen(
        cmdResponse,
        cmdResponse,
        pageIndex,
        pageSize
      )
      if (!linkToRedirect) {
        linkToRedirect += `${pathname}?count=${count + 1}`
      } else linkToRedirect += `&count=${count + 1}`
      navigate(linkToRedirect, { replace: true })

      dispatch(clearCurrentDestinataire())
      dispatch(clearCurrentTransporteur())
      setLoading(false)
    } catch (error: any) {
      let e = error.toString()
      setLoading(false)
      if (e.includes('406')) {
        setIsOpenModalDeleteCommande(true)
      } else if (e.includes('409')) {
        setIsOpenModalConfirmModifyDocument(true)
      } else {
        notification.open({
          className: 'noti noti-error',
          message: (
            <div className="flex items-center">
              <img src={ERROR} alt="error" width={50} className="mr-2.5" />
              Une erreur s'est produite. Merci de réessayer ultérieurement
            </div>
          ),
          placement: 'topRight',
          closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
          duration: 3,
        })
        navigate(
          `/gestion-de-stock/commande/commande-preparation/${
            cmdResponse?.id
          }?page-index=${pageIndex}&page-size=${pageSize}&isError=true${getCurrentFiltersText()}`
        )
      }
    }
  }

  const onEnvoyerChargement = async (fileId: string) => {
    setSubmitted(false)

    if (detailData.length === 0 && !isValidDetail) return

    setLoading(true)

    try {
      const entry = (
        await commandeApi.getCommandeById({ id: params?.id || '' })
      ).data.entry

      //update commande
      const envoyeRecapFile = setStateForFileWhenEnvoyePR(
        entry.com_file || [],
        [{ id: fileId, name: '' }]
      )

      const recapFile = (
        envoyeRecapFile.find((el) =>
          el.name.includes('Feuille de récap')
        ) as FileResponse
      ).name

      const firstChar = recapFile.indexOf('(')
      const lastChar = recapFile.lastIndexOf(')')
      const fileName = recapFile.substring(firstChar + 1, lastChar)

      await commandeApi.updateCommande({
        entry: {
          ...entry,
          client_ob: undefined,
          com_file: envoyeRecapFile,
          modification_by: localStorage.getItem(Define.USERNAME) || '',
          modificationer_id: localStorage.getItem(Define.USER_ID) || '',
          com_article: undefined,
          com_livraison: undefined,
          com_preparation: undefined,
          des_addresse: undefined,
          des_contact: undefined,
          destinataire: undefined,
          tran_addresse: undefined,
          tran_contact: undefined,
          transporteur: undefined,
          scene: CommandeScene.ChargementScene,
        },
        details_delete: { id: [] },
        files_delete: { id: [] },
      })

      //envoyer chargement
      const comArticleMapping = entry.com_article
        ?.filter((item) => item.mission_id && item.source === sourceType.AUTO)
        .map((item) => {
          item.status_code = StatusCommande.MissionUnassigned
          item.mouvement = 'SO'
          item.type = '60'
          item.eta = EtaCommande.MissionUnassigned
          delete item.conditionnement
          return item
        })

      await commandeApi.envoyerEnMission({
        entry: {
          parent_id: entry?.id || '',
          modification_by: localStorage.getItem(Define.USERNAME) || '',
          modificationer_id: localStorage.getItem(Define.USER_ID) || '',
          validation_by: localStorage.getItem(Define.USERNAME) || '',
          validationer_id: localStorage.getItem(Define.USER_ID) || '',
          validation_time: new Date().getTime(),
          status_code: StatusCommande.MissionUnassigned,

          com_article: comArticleMapping,
          priorite: entry?.priorite || 5,
          company_code_nom: entry?.company_code_nom || '',
          warehouse_code_nom: entry?.warehouse_code_nom || '',
          client_code_nom: entry?.client_code_nom || '',
          nom: `CH-${fileName}`,
          mission_type: MissionType.ChargementMission,
        },
        scene: CommandeScene.ChargementScene,
        status_code: StatusCommande.SendOnMissionCommande,
      })

      let response = await commandeApi.getCommandeById({
        id: cmdResponse?.id || '',
      })

      let linkToRedirect = redirectToCmdScreen(
        response.data.entry,
        response.data.entry,
        pageIndex,
        pageSize
      )
      if (!linkToRedirect) {
        linkToRedirect += `${pathname}?count=${count + 1}`
      } else linkToRedirect += `&count=${count + 1}`
      navigate(linkToRedirect, { replace: true })
      dispatch(clearCurrentDestinataire())
      dispatch(clearCurrentTransporteur())
      setLoading(false)
    } catch (e) {
      setLoading(false)
      notification.open({
        className: 'noti noti-error',
        message: (
          <div className="flex items-center">
            <img src={ERROR} alt="error" width={50} className="mr-2.5" />
            Une erreur s'est produite. Merci de réessayer ultérieurement
          </div>
        ),
        placement: 'topRight',
        closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
        duration: 3,
      })
    }
  }

  const onEditing = (isEditing: boolean) => {
    setIsAllowEnvoyerEnMission(!isEditing)
    setIsEditingDetailTable(isEditing)
  }

  const onCloseModalConfirmModify = async () => {
    if (isOpenModalConfirmModifyDocument) {
      setIsOpenModalConfirmModifyDocument(false)
    }
    if (isOpenModalConfirmModifyTable) {
      setIsOpenModalConfirmModifyTable(false)
    }
    setLoading(true)

    try {
      let response = await commandeApi.getCommandeById({
        id: cmdResponse?.id || '',
      })
      let newResponse = cloneDeep(response.data.entry)

      let linkToRedirect = redirectToCmdScreen(
        newResponse,
        cmdResponse,
        pageIndex,
        pageSize
      )

      if (!linkToRedirect) {
        linkToRedirect += `${pathname}?count=${count + 1}`
      } else linkToRedirect += `&count=${count + 1}`
      navigate(linkToRedirect, { replace: true })
      dispatch(clearCurrentDestinataire())
      dispatch(clearCurrentTransporteur())
    } catch (e: any) {
      if (e.response.data.error.includes('record not found')) {
        handleClose()
      }
    } finally {
      setLoading(false)
    }
  }

  const onCloseModalDeleteCommande = () => {
    handleClose()
  }

  useEffect(() => {
    focusById(commandeKey['n-client'])
  }, [])

  return (
    <>
      <Form id={'commande-metre'} onFinish={onFinishForm} form={form}>
        <Modal
          prefixCls="modal-new-stock"
          centered
          open={true}
          onCancel={handleClose}
          width={'90%'}
          className="border-solid border-secondary rounded-xl"
          footer={null}
          destroyOnClose={true}
        >
          {(loading || initLoading) && <Loading />}
          <div
            className="flex items-baseline border-none pb-2 mb-2"
            style={{ borderBottom: '1px solid #B7B7B7' }}
          >
            <div className="flex items-center">
              <span className="modal-title-heading-1 mr-1">Commande</span>
              <span className="text-[#20458F] text-2xl">
                (n° {cmdResponse?.nom})
              </span>
              <span
                className={`${
                  commandeType.toString() === CommandeType.Mis_en_preparation
                    ? `mise`
                    : `envoyer`
                }    commande-title`}
              >
                {commandeType.toString() === CommandeType.Envoyer_en_chargement
                  ? 'En chargement'
                  : commandeType.toString()}
              </span>
            </div>
          </div>
          <CustomPopup
            title={'Commande supprimée'}
            type={popupType.CONFIRM}
            isOpen={isOpenModalDeleteCommande}
            onConfirm={onCloseModalDeleteCommande}
          >
            <p className="text-xl">
              La commande a été supprimée par un autre utilisateur.
            </p>
          </CustomPopup>
          <CustomPopup
            title={
              isOpenModalConfirmModifyTable
                ? 'Confirmation de mise en préparation'
                : 'Confirmation de l’envoi en mission'
            }
            type={popupType.VALIDE}
            isOpen={
              isOpenModalConfirmModifyTable || isOpenModalConfirmModifyDocument
            }
            onConfirm={onCloseModalConfirmModify}
          >
            <p className="text-xl">
              La commande doit-être mise à jour pour prendre en compte <br />{' '}
              les derniers renseignements saisis.
            </p>
          </CustomPopup>

          <div className="flex justify-between italic  text-[#848484] mt-1">
            <div className="text-[#848484]">
              {`Création : ${moment(
                (cmdResponse?.created_at || 0) * 1000
              ).format(t('time-format'))}`}
            </div>
            <div className="text-[#848484]">
              {`Modification : ${moment(
                (cmdResponse?.updated_at || 0) * 1000
              ).format(t('time-format'))}`}
            </div>
          </div>
          <div className="h-2"></div>
          <div className="overflow-y-auto main-content-2">
            <SectionLayout title="Informations">
              <div className="flex infomation-section justify-between">
                <Form.Item
                  label="N°"
                  name="nom"
                  rules={[{ required: true, message: '' }]}
                  colon={false}
                  className="form-label"
                >
                  <Input maxLength={20} disabled />
                </Form.Item>

                <Form.Item
                  label="N° client"
                  name="nom_client"
                  colon={false}
                  className="item-client-code form-label"
                  validateStatus={errorClientCode ? 'error' : undefined}
                  help={errorClientCode}
                >
                  <Input
                    id={commandeKey['n-client']}
                    data-next-id={commandeKey['priorite']}
                    disabled={isComplete || isChargement}
                    maxLength={20}
                    onChange={(e) => {
                      form.setFieldValue(
                        'nom_client',
                        removeTones(e.target.value)
                      )

                      setErrorClientCode('')
                    }}
                  />
                </Form.Item>

                <Form.Item label="Client" colon={false} className="form-label">
                  <Input
                    disabled
                    style={{ background: '#F2F2F2' }}
                    value={cmdResponse?.client_code_nom}
                  />
                </Form.Item>
                <Form.Item
                  label="Priorité"
                  name="priorite"
                  colon={false}
                  className="priorite-item form-label"
                >
                  <Select
                    id={commandeKey['priorite']}
                    data-next-id={commandeKey['Forcer-la-DLUO']}
                    disabled={isComplete || isChargement}
                    style={{ width: 87 }}
                    className="responsive-input"
                  >
                    {[5, 4, 3, 2, 1].map((i) => (
                      <Select.Option key={i} value={i}>
                        {i}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </SectionLayout>
            {!initLoading ? (
              <DetailSectionMEP
                onChangeForcerDluo={onChangeForcerDluo}
                onCheckExistWaitingLine={onCheckExistWaitingLine}
                block={
                  cmdResponse?.status_code &&
                  ![
                    StatusCommande.CreatedCommande,
                    StatusCommande.ValidCommande,
                    StatusCommande.AssignedCommande,
                    StatusCommande.InProgressCommande,
                  ].includes(cmdResponse?.status_code)
                }
                onEditing={onEditing}
                screen={CommandeType.Mis_en_preparation}
                mode={MODE.EDIT}
                initData={detailDataFromApi}
                onCheckValid={onCheckValid}
                onChangeData={onChangeDataDetail}
                cmdResponse={cmdResponse}
                isComplete={isComplete}
                clientRule={
                  cmdResponse?.client_ob?.fifo
                    ? ClientRule.FIFO
                    : ClientRule.FEFO
                }
              />
            ) : null}
            <SectionLayout
              title="Documents"
              action={
                <Action onClickPlusBtn={() => setOpenPopupImport(true)} />
              }
            >
              <DisplayDocumentsCmd
                initData={
                  !checkExistWaitingLine &&
                  checkNotExistAutoFindLine &&
                  checkLastMission &&
                  (cmdResponse?.scene === CommandeScene.TotalScene ||
                    cmdResponse?.scene === CommandeScene.ChargementScene)
                    ? cmdFile
                    : cmdFile.filter(
                        (el) => !el.name.includes('Feuille de récap')
                      )
                }
                type={ModalName.COMMANDE}
                openImport={openPopupImport}
                closeImport={() => setOpenPopupImport(false)}
                onChangeData={onChangeDataFile}
                isSendOnPreparation
                onEnoyerEnMission={onEnvoyerEnMission}
                isAllowEnvoyerEnMission={
                  isAllowEnvoyerEnMission && !changedDetailData
                }
                onEnvoyerChargement={onEnvoyerChargement}
              />
            </SectionLayout>
            {cmdResponse?.mission && (
              <>
                <MissionList
                  commandeType={commandeType}
                  cmdResponse={cmdResponse}
                />
                <SectionLayout title="Suivi">
                  {cmdResponse ? (
                    <SuiviCommande cmdResponse={cmdResponse} />
                  ) : null}
                </SectionLayout>
              </>
            )}
            <FormLivraison
              isCreateTransporteur={isCreateTransporteur}
              setIsCreateTransporteur={setIsCreateTransporteur}
              isEmptyTransporteur={isEmptyTransporteur}
              onChangeTransporteur={onChangeTransporteur}
              gln={gln}
              form={form}
              isDisabled={isComplete || isChargement}
              detailLength={detailData.length}
            />

            <FormDestination
              isCreateDestinataire={isCreateDestinataire}
              setIsCreateDestinataire={setIsCreateDestinataire}
              isEmptyDestinataire={isEmptyDestinataire}
              onChangeDestinataire={onChangeDestinataire}
              currentTransporteur={currentTransporteur}
              form={form}
              currentDestinataire={currentDestinataire}
              dataDestinataireAdresses={dataDestinataireAdresses}
              dataDestinataireContact={dataDestinataireContact}
              handleAttDestinataire={handleAttDestinataire}
              isDisabled={isComplete || isChargement}
              displayRelay={displayRelay}
            />
            <SectionLayout title="Commentaire">
              <div className="flex">
                <Form.Item
                  name={['com_preparation', 'comment']}
                  label="Préparation"
                  colon={false}
                >
                  <TextArea
                    style={{
                      height: 100,
                      width: 390,
                      resize: 'none',
                      marginLeft: '7%',
                    }}
                    showCount
                    maxLength={200}
                    disabled={disabledCommentaire}
                    id={commandeKey['preparation-commentaire']}
                    data-previous-id={commandeKey['code-nom-destinataire']}
                    data-next-id={commandeKey['livraison-commentaire']}
                  />
                </Form.Item>
                <Form.Item
                  name={['com_livraison', 'comment']}
                  label="Livraison"
                  colon={false}
                  className="ml-10"
                >
                  <TextArea
                    style={{
                      height: 100,
                      width: 390,
                      resize: 'none',
                      marginLeft: '7%',
                    }}
                    showCount
                    maxLength={200}
                    disabled={disabledCommentaire}
                    id={commandeKey['livraison-commentaire']}
                    data-previous-id={commandeKey['preparation-commentaire']}
                    data-next-id={commandeKey['annuler']}
                  />
                </Form.Item>
              </div>
            </SectionLayout>
          </div>

          <>
            {props.commandeType !== CommandeType.Edit ? (
              <div className="flex justify-between items-center footer-content">
                {!isComplete && !isChargement && (
                  <>
                    <div>
                      <button
                        id={commandeKey['annuler']}
                        disabled={loading || isAtLeastOneFileSendOnMission}
                        onClick={onCancelCmd}
                        className={clsx(
                          ' py-1 mr-4 rounded-lg text-lg font-semibold',
                          !isAtLeastOneFileSendOnMission
                            ? 'supprimer pl-12 pr-5 border-red border-solid bg-white hover:text-red text-red  relative cursor-pointer'
                            : 'px-5 bg-[#838483] border-none text-white hover:text-white cursor-not-allowed'
                        )}
                      >
                        Annuler mise en préparation
                      </button>
                      <button
                        disabled={loading}
                        className={clsx(
                          'remetre border-[#01E37A] border-solid bg-white text-[#01E37A] hover:text-[#01E37A] text-lg font-semibold  pl-12 pr-5 py-1 rounded-lg relative',
                          !loading ? 'cursor-pointer' : 'cursor-not-allowed'
                        )}
                        onClick={miseEnPreparation}
                      >
                        Remettre en préparation
                      </button>
                    </div>
                    <Button
                      data-next-id={commandeKey['n-client']}
                      disabled={
                        changedDetailData ||
                        isEditingDetailTable ||
                        loading ||
                        currentDestinataire.status === StatusAPI.calling ||
                        currentTransporteur.status === StatusAPI.calling
                      }
                      prefixCls=" text-btn-submit-last-modal"
                      className={
                        !(
                          changedDetailData ||
                          isEditingDetailTable ||
                          loading ||
                          currentDestinataire.status === StatusAPI.calling ||
                          currentTransporteur.status === StatusAPI.calling
                        )
                          ? clsx(
                              'btn-submit-modal text-btn-submit-last-modal  hover:text-white',
                              !loading ? 'cursor-pointer' : 'cursor-not-allowed'
                            )
                          : clsx(
                              'bg-[#838483] px-5 py-2 border-none rounded-lg hover:text-white',
                              !loading ? 'cursor-pointer' : 'cursor-not-allowed'
                            )
                      }
                      key="submit"
                      htmlType="submit"
                      form={'commande-metre'}
                    >
                      Sauvegarder
                    </Button>
                  </>
                )}
              </div>
            ) : null}
          </>
        </Modal>
      </Form>
      <ModalConfirm
        isOpen={isOpenModalConfirmCancel}
        onOk={confirmCancel}
        onCancel={notConfirmCancel}
      />
    </>
  )
}

export default CommandeMisEnPreparation

const ModalConfirm = ({
  isOpen = false,
  onOk = () => {},
  onCancel = () => {},
}) => {
  const commandeKey = useSelector((state: RootState) => state.selector).data
    .commande
  const style: React.CSSProperties = {
    fontFamily: 'Montserrat',
    fontSize: 21,
    fontWeight: 400,

    textAlign: 'left',
  }
  return (
    <Modal
      title={
        <span className="modal-title-heading-1">
          Annuler la mise en préparation
        </span>
      }
      centered
      open={isOpen}
      onOk={onOk}
      onCancel={onCancel}
      width={800}
      confirmLoading
      footer={
        <div>
          <button
            onClick={onCancel}
            id={commandeKey['annuler']}
            data-next-id={commandeKey['confirmer']}
            className="mr-4 border-red border-solid bg-white hover:text-red text-red text-lg font-semibold px-8 py-1 rounded-lg cursor-pointer relative"
          >
            Annuler
          </button>

          <button
            onClick={onOk}
            id={commandeKey['confirmer']}
            data-previous-id={commandeKey['annuler']}
            className="border-green border-solid bg-green text-white hover:text-white text-lg font-semibold  px-8 py-1 rounded-lg cursor-pointer relative"
          >
            Confirmer
          </button>
        </div>
      }
    >
      <div style={{ height: 1, background: '#b7b7b7' }}></div>
      <div style={{ height: 15 }}></div>
      <div>
        <span style={style}>
          Etes-vous sûr(e) de vouloir annuler la préparation?
        </span>
        <br />
        <span style={style}>
          En cas d'annulation, les quantités confirmées modifiées et les motifs
          renseignés ne seront pas conservés.
        </span>
      </div>
    </Modal>
  )
}
