export default function UserIcon() {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_21109_19882"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="27"
        height="27"
      >
        <path
          d="M13.5 27C20.9558 27 27 20.9558 27 13.5C27 6.04416 20.9558 0 13.5 0C6.04416 0 0 6.04416 0 13.5C0 20.9558 6.04416 27 13.5 27Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_21109_19882)">
        <rect
          x="2.89282"
          y="15.8574"
          width="20.0357"
          height="18.8571"
          rx="5"
          fill="#FFAA06"
        />
      </g>
      <circle cx="13.4999" cy="10.6066" r="4.82143" fill="#FFAA06" />
      <path
        d="M26.25 13.5C26.25 20.5416 20.5416 26.25 13.5 26.25C6.45837 26.25 0.75 20.5416 0.75 13.5C0.75 6.45837 6.45837 0.75 13.5 0.75C20.5416 0.75 26.25 6.45837 26.25 13.5Z"
        stroke="#FFAA06"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </svg>
  )
}
