export default function HourGlass() {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="13.5"
        cy="13.5"
        r="12.75"
        stroke="#0189E3"
        stroke-width="1.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9147 13.5L8.55539 6.53028C8.13881 5.86425 8.61763 5 9.40321 5H18.5527C19.3383 5 19.8171 5.86425 19.4006 6.53028L15.0413 13.5L19.4006 20.4697C19.8171 21.1358 19.3383 22 18.5527 22H9.40321C8.61763 22 8.13881 21.1358 8.55539 20.4697L12.9147 13.5ZM14.3928 12.3174C14.1946 12.6117 13.7616 12.6117 13.5634 12.3174L11.0678 8.61244C10.8441 8.28032 11.0821 7.83311 11.4825 7.83311L16.4737 7.83311C16.8741 7.83311 17.1121 8.28032 16.8884 8.61244L14.3928 12.3174ZM13.978 16.3335C13.6651 16.3335 13.4114 16.5872 13.4114 16.9002C13.4114 17.2131 13.6651 17.4668 13.978 17.4668C14.291 17.4668 14.5447 17.2131 14.5447 16.9002C14.5447 16.5872 14.291 16.3335 13.978 16.3335ZM13.4114 14.6336C13.4114 14.3206 13.6651 14.0669 13.978 14.0669C14.291 14.0669 14.5447 14.3206 14.5447 14.6336C14.5447 14.9465 14.291 15.2002 13.978 15.2002C13.6651 15.2002 13.4114 14.9465 13.4114 14.6336ZM10.4501 20.1148L11.189 18.8482C11.2786 18.6946 11.443 18.6001 11.6209 18.6001H16.3354C16.5132 18.6001 16.6777 18.6946 16.7673 18.8482L17.5062 20.1148C17.7006 20.4482 17.4602 20.8668 17.0743 20.8668H10.882C10.4961 20.8668 10.2557 20.4482 10.4501 20.1148Z"
        fill="#0189E3"
      />
    </svg>
  )
}
