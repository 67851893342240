import { Col, Row } from 'antd'
import React, { FC } from 'react'
import { CInput } from '../stockscreen/component/Wrapper'
import { CommandeResponse } from '../../../models'
import { StatusCommande } from '../../../enum/StatusCommande'
import { MissionType } from '../../../enum/MissionType'

const SuiviCommande: FC<{ cmdResponse: CommandeResponse }> = ({
  cmdResponse,
}) => {
  const missionCH = cmdResponse.mission?.filter(
    (el) => el.mission_type === MissionType.ChargementMission
  )

  const isValide =
    Number(missionCH?.length) > 0
      ? missionCH?.every((el) => el.status_code === StatusCommande.MissionDone)
      : false
  const uniqueBls = [
    ...new Set(
      cmdResponse?.com_article
        ?.filter((item) => item.stock?.loading_bl)
        ?.map((item) => item.stock?.loading_bl)
    ),
  ]
  return (
    <div className="suivi">
      <Row>
        <Col span={20}>
          <Row justify="space-between">
            <Col span={8}>
              <Row align="middle" gutter={12}>
                <Col span={8}>
                  <span className="form-label">Mouvement</span>
                </Col>
                <Col span={16}>
                  <CInput
                    value="Sortie normale"
                    className="custom-input"
                    disabled
                  />
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row align="middle" gutter={12}>
                <Col span={4}>
                  <span className="form-label">Type</span>
                </Col>
                <Col span={18}>
                  <CInput
                    value="Commande de sortie manuelle"
                    className="custom-input"
                    disabled
                  />
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row align="middle" gutter={12}>
                <Col>
                  <span className="form-label">Etat</span>
                </Col>
                <Col>
                  <CInput
                    value={isValide ? 'Validé' : 'En cours'}
                    className="custom-input"
                    disabled
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-12 border-0 border-b-[1px] border-b-[#808080] border-solid">
        <div className="w-40 py-2 text-center form-label">BL</div>
        <div className="w-fit px-8 py-2 form-label">
          {uniqueBls?.length ? uniqueBls?.join(', ') : '-'}
        </div>
      </Row>
    </div>
  )
}

export default SuiviCommande
